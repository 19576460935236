<template>
  <DashboardTemplate>
    <div class="min-height-screen-120 text-center pd-5 position-relative">
      <div class="position-absolute" style="top: 20%; left: 0; right: 0">
        <h3 class="mg-t-1">คุณต้องการยืนยันการส่งแบบประเมิน?</h3>
        <p class="text-grey mg-y-6">หากคุณกดส่งแบบประเมินแล้ว</p>
        <p class="text-grey mg-y-6">
          จะไม่สามารถกลับมาแก้ไขข้อมูลที่กรอกไปแล้วได้
        </p>
        <div class="is-flex js-between ai-center mg-t-1">
          <router-link to="/factor" class="text-grey-light font-18"
            ><i class="el-icon-arrow-left"></i> ย้อนกลับ</router-link
          >
          <div>
            <el-button
              type="primary"
              :loading="loadingConfirm"
              @click="confirmAssessment"
              >ยืนยัน</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardTemplate
  },
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  data() {
    return {
      loadingConfirm: false
    };
  },
  methods: {
    confirmAssessment() {
      this.loadingConfirm = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`confirm/assessment`)
        .then(res => {
          if (res.data.success) {
            this.$router.push("/send-success").catch(err => {});
          } else {
            this.alertFailError();
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        })
        .finally(() => {
          this.loadingConfirm = false;
        });
    }
  }
};
</script>
